.otherCourse1{
  &__lastImg{
    position: relative;
    height: 80vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: -15rem;
    @include respond(tab-port){
        height: 50vh;
        margin-bottom:-5rem;
    }
    &-blur{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 15rem;
      // z-index: 5;
      background-image: linear-gradient(to top,rgba($color-white,1) 30% ,transparent 100%);
      color: transparent;
      @include respond(tab-port){
          display: none;
      }
    }
    & > img{
        // height:  200%;
        width: 80%;
        object-position: top;
        object-fit: cover;
        @include respond(tab-port){
          width: 100%;
          height: 39vh;
          object-position: left;
        }
    }
    & a{
      @include respond(tab-port){
        height: 5rem;

      }
      & img{
        height: 100%;
      }

    }
    &-btn{
      position:absolute;
      left: 32%;
      object-fit: cover;
      transition: all .2s;
      // &:hover{
      //   box-shadow: 0 5px 1rem rgba($color-grey,.4);
      //   transform: translateY(-3px);
      // }
      @include respond(tab-port){
        transform: translateX(-50%);

      }
    }
    &-apple{
      top: 35%;
      @include respond(tab-port){
          left: 30%;
          top: 80%;
      }
    }
    &-play{
      top: 50%;
      @include respond(tab-port){
          left: 69%;
          top: 80%;
      }
    }
  }
  &__whyJoin{
    width: 100%;
    margin: 0 auto;
    display: none;
    @include respond(tab-port){
      display: flex;
      justify-content: center;
    }
  }
  &__whyJoin2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @include respond(tab-port){
      display: none;
    }
  }
  &__Wrapper{
    // width: 100vw;
    position:relative;
    min-height: 100vh;
    margin-top: 5rem;
    // overflow-x: hidden;
    @include respond(tab-port){
       min-height: auto;

    }
    &_selected{
      color: #D0D0D0;
      font-size: 10rem;
      text-align: right;
      padding-right: 12rem;
      padding-top: 7rem;
      font-weight: bold;
      // width: 50%;
      @include respond(tab-port){
        // display: none;
        color: $color-exim-dark-grey;
        font-size: 2.5rem;
        text-align: left;
        padding: 2rem 1rem;
        padding-bottom: 4rem;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    &_row1{
      display: flex;
      justify-content: flex-start;
      align-items:center;
      margin-left: 15%;
      @include respond(tab-port){
        display: none;
      }
      &--item{
        font-size: 2.2rem;
        color: $color-exim-blue;
        font-weight: bold;
        margin-right: 15rem;
        cursor: pointer;
        transition: all .3s;
        padding: 1rem;
        // &:hover{
        //   text-shadow: 0rem 5px 2rem rgba($color-exim-grey,1);
        //   transform: translateY(-3px);
        // }
      }
      @include respond(tab-port){
        box-shadow: 0 0 2rem rgba($color-exim-grey,.4);
        margin: 2rem 1rem;
        // background-image: linear-gradient(to right, $color-exim-blue 30%, transparent 30%);
        justify-content: space-between;
        border-radius: 8px;
        overflow: hidden;
        // &--1{
        //   background: $color-exim-blue;
        //   color: $color-white;
        // }
        &--item{
          font-size: 2rem;
          margin: 0rem 0rem;
          flex-grow: 1;
          text-align: center;
        }
      }
    }
    & img{
      width: 100%;
      // margin-left: -12rem;
      position: absolute;
      top: -6.5rem;
      left: 0rem;
      // height: 100vh;

      z-index: -1;
      width: 100%;
      object-fit: cover;
      object-position: top;
      @include respond(tab-land){
        top: -5rem;
        left: 0rem;
      }
      @include respond(tab-port){
        display: none;

      }
    }
  }
}
.middle{
  margin: 0 10rem;
  margin-bottom: 20rem;
  @include respond(tab-port){
    display: none;
  }
  &__lesson{
      &_item{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 4rem;
        width: 60%;
        margin-left: 05rem;
        @include respond(tab-port){
          margin-bottom: 2rem;
          width: 100%;
          margin-left: 0rem;
        }
        & > img{
          position: static;
          height: 8rem;
          width:8rem;
        }
        &-middle{
          flex-grow: 1;
           margin: 0 10rem 0 5rem;
           @include respond(tab-port){
              margin: 0 1rem 0 1rem;

           }
           & .title{
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 1rem;
                color: $color-exim-blue;
                @include respond(tab-port){
                  color: $color-exim-dark-grey;
                  font-weight: 500;

                }
           }
           & .subtitle{
                font-size: 2rem;
                color: $color-exim-dark-grey;
                font-weight: bold;
                margin-bottom: 1rem;

           }
           & .lesson{
             font-size: 1.5rem;
             color: $color-exim-dark-grey;
             transition: all .5;
             @include respond(tab-port){
                margin: 0;

             }
           }
        }
        &-right{
          padding: 1rem 1rem;
          background: $color-exim-blue;
          color: white;
          font-size: 1.5rem;
          display: flex;
          justify-content:center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          transition: all .5;
          &:hover{
            transform: translateY(-3px);
            box-shadow: 0 0 2rem rgna($color-exim-grey,.5);
          }
          & *{
            margin: 0 1rem;
          }
          & div{
          }
          & span{

          }
          & svg{
            transition: all .5s;
            // position: relative;
            // top: .5rem;
            transform-origin: center;

          }
          @include respond(tab-port){
            padding: 0;
            background: white;
            display: flex;
            justify-content:center;
            align-items: center;
            border-radius: 8px;
          }
        }
      }
  }
  &__detail{
    font-size: 2rem;
    line-height: 1.5;
    padding-top: 5rem;
    color: #555555;
  }
  &__review{
    &_flex{
      display: flex;
      justify-content:flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      &-more{
        flex-basis: 100%;
        color: $color-exim-blue;
        background: $color-white;
        font-size: 2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        & span{
          margin-right: 2rem;
        }
      }
      &-div{
        flex-grow: 1;
        margin-left: 2.5rem;
      }
      &-svg{
        margin-bottom: 1rem;
        @include respond(tab-port){
          margin-bottom: .5rem;
        }
      }
      &-name{
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 1rem;
        color: #555555;

      }
      &-rating{
        & > *{
          margin-right: .5rem;
          @include respond(tab-port){
            height: 3rem;
            margin-right: 0rem;
          }
        }
      }
      &-review{
          flex-basis: 100%;
          font-size: 2rem;
          line-height: 1.5;
          padding-top: 5rem;
          color: #555555;
          @include respond(tab-port){
            font-size: 1.7rem;
            padding-top: 2rem;
            font-weight: 600;
          }
      }
    }
  }
}
.middleMob{
  display: none;
  @include respond(tab-port){
    display: block;
  }
  &__lesson{

  }
  &__detail{
    font-size: 1.8rem;
    line-height: 1.5;
    padding: 3rem 1rem;
    color: #555555;
    font-weight: 600;


  }
  &__review{
    & img{
      // width: 90vw;
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
      margin: 0;
      padding: 0;

    }
  }
}

  .otherCourse1__Wrapper_row2{
      display: none;
      @include respond(tab-port){
      display: flex;

      &--item{
        font-size: 2rem;
      }
      &--selected{
         color:white;
         background: $color-exim-blue;
         border-radius: 8px;
      }
  }
}
#review{
  display: flex;
  justify-content: flex-end;
  @include respond(tab-port){
    justify-content:flex-start;
  }
  & span{
    margin-right: 1rem;
  }
  & svg{
    height: 3rem;

  }
}
