.goToTop{
  cursor: pointer;
  z-index: 500;
  position: fixed;
  height: 6rem;
  width: 6rem;
  border-radius:100px;
  display: flex;
  justify-content: center;
  align-items:center;
  bottom: 2rem;
  left: 6rem;
  display: none;
  & i{
    font-size: 5rem;
    color: $color-grey;
    background-image: radial-gradient($color-grey,$color-grey-dark-3);
    background-clip: text;

  }
}
