.landing__8{
  margin-bottom: 10rem;
  &_bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    &-box{
      background: $color-white;
      margin: 1rem;
      margin-bottom: 2.5rem;
      cursor: pointer;
      min-width: 10rem;
      box-shadow: 0rem 0rem 2rem rgba($color-exim-grey,.2);
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      padding: 1.5rem;
      border-radius: 10px;
      transition: all .5s;
      min-height: 13rem;
      min-width: 13rem;
      &:hover{
        box-shadow: 0 .5rem 1rem rgba($color-exim-grey,.2);
        transform: translateY(-4px);
      }
      &:active{

        box-shadow: 0 .4rem 1rem rgba($color-exim-grey,.2);
        transform: translateY(-1px);
      }
      & img{
         width: 5rem;
         margin-bottom: 1rem;
      }
      & div{
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }
  &_box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-images{
      position: relative;
      @include respond(tab-port){
        display: none;
      }
      & > img{

      }
    }
    &-icons{
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      padding: 4rem 2rem;
      box-shadow: 0 0 3rem rgba($color-exim-blue,.7);
      border-radius: 18px;
      background: $color-white;
      transition: all .3s;
      &:hover{
        box-shadow: 0 0 3rem rgba($color-exim-blue,1), 0 0 5rem rgba($color-exim-blue,.7);
      }
      & > *{
        margin: 2rem;
      }
      &--1{
        cursor: pointer;
        color:$color-exim-blue;
      }
      &--2{

      }
      &--3{
         cursor: pointer;
      }
    }

    &-text{
      border: 1px solid rgba($color-grey,.2);
      box-shadow: 0 0 2rem rgba($color-grey,.4);
      max-width: 50rem;
      padding: 5rem;
      background: $color-white;
      @include respond(tab-port){
        padding: 2rem;
      }
      &--big{
        font-size: 1.8rem;
        color: $color-exim-dark-grey;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        @include respond(tab-land){
          margin-top: 5rem;
        }
      }
      &--small{
        font-size: 1.4rem;
        color: $color-grey;
        margin-bottom: 1rem;
      }
    }
    &-flex{
      display: flex;
      display: none;
      justify-content: space-around;
      align-items: center;
      margin-top: 10rem;
      &--text{
        font-size: 2.5rem;
        color: $color-exim-grey;
        font-weight: bold;
        text-transform: uppercase;
      }
      &--btn{
         border: none;
         padding: 2rem;
         box-shadow: .1rem .1rem .1rem rgba($color-black,.3);
         background: $color-white;
         &:hover{
           box-shadow: 0 2rem 3rem rgba($color-exim-dark-grey,.2);
           transform: translateY(-4px);
         }
         &:active{
           box-shadow: 0 1rem 3rem rgba($color-exim-dark-grey,.2);
           transform: translateY(-1px);
         }
         &:first-child{
           color: $color-exim-blue;
         }
         &:last-child{
           color: $color-grey;
         }
         & img{

         }
         &-1{
           & div{
             color: $color-exim-blue;
           }
         }
         & div{
            font-weight: bold;
            margin-top: 1rem;
            font-size: 1.2rem;
         }
      }
    }
  }
}

.bubble{
  animation: faq 50s linear infinite;
  animation-fill-mode: forwards;
}

.selectedIcon{
  box-shadow: 0 2rem 3rem rgba($color-exim-blue,.5);
  transition: all .2s;
  // background-image: linear-gradient(to right,#1069DC,#1069DC);
  background: #0062FF;
  color: $color-white;
}

@include respond(tab-port){
  .landing__8{
    &_box{
      flex-direction: column;
      text-align: center;
      line-height: 1.5;
      &-images > img{
        width: 85vw;
        margin-bottom: 2rem;
      }
      &-flex{
      }
    }
  }
};
