.landing__nextBD{
  text-align: center;
  height: 50rem;
  margin: 0 5rem;
  box-shadow: 0 -5rem 15rem rgba($color-grey,.5);
  border-radius: 18px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: relative;
  padding: 0 10rem;
  @include respond(tab-port){
      & > img{
        display: none;
      }
  }
  &_flex{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    & a{
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-exim-green;
      font-size: 2.5rem;
      margin-top: 2rem;
      font-weight: bold;
      @include respond(tab-port){
          font-size: 2rem;
      }
      & img{
        margin-right: 1rem;


      }
    }

  }
  @include respond(tab-port){
       margin: 0 2rem;
       justify-content: flex-start;
  }
  &_head{
    font-size: 6.5rem;
    color: #606060;
    font-weight: bolder;
    position: relative;
    & img{
      position: absolute;
      right: 85%;
      bottom: 60%;
      @include respond(tab-port){
          height: 8rem;
          right: 65%;
          bottom: 85%;
      }
    }
  }
  &_date{
    font-size: 4.5rem;
    color: #939393;
    font-weight: 400;
  }
  & > a{
    color: $color-exim-blue;
    height: 5rem;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 600;
    position: absolute;
    right: 10%;
    bottom: 10%;
    & span{
      margin-left: -70%;
      text-align: left;
    }
  }
}
