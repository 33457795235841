.footer{
  background: #F5F5F5;
  border-top: 1px solid rgba($color-black,.1);
  box-shadow: 0 0 2rem rgba($color-black,.1);
  width: 100vw;
  position: relative;
  left: -12rem;
  padding: 4rem 10rem;
  margin-top: 10rem;
  overflow: hidden;
  &__top{
    display: flex;
    justify-content: space-between;
    &_text{
     &-big{
       margin-left: -.5rem;
       font-size: 11rem;
       letter-spacing: 2.3rem;
       color: $color-exim-dark-grey;
       font-weight: bold;
       margin-bottom: -2rem;
     }
     &-small{
       font-size: 5rem;
       letter-spacing: .5rem;
       color: $color-exim-dark-grey;
       font-weight: lighter;
       margin-bottom: 1rem;
     }
     &-smaller{
       font-size: 1.4rem;
       font-weight: bold;
       letter-spacing: .2rem;
       margin-bottom: 1rem;
       line-height: 1.3;
       margin-left: .5rem;
     }
    }
    &_social{
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;
      &-heading{
        font-size: 2.2rem;
        color: $color-black;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-top: 3rem;
        text-align: center;
      }
      & button{
        margin: auto;
        margin-top: 2rem;
        margin-left: 8rem;
        @include respond(tab-land){
          margin-left: 0rem;
        }
      }
      &-icons{
        display: flex;
        align-items: center;
        & > a >i{
          font-size: 4rem;
          color: $color-exim-dark-grey;
        }
       & > *{
         margin: 2rem;
         font-size: 1.5rem;
         text-decoration: none;
         color: $color-exim-dark-grey;
         font-weight: bold;
         &:hover{
           animation: shake .3s linear infinite;
           text-decoration: none;
         }
       }
      }
    }
  }
  &__middle{
    font-size: 1.6rem;
    text-decoration: none;
    color: $color-black;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7rem;
    margin-bottom: 5rem;
    & div{
      width: 55rem;
      text-align: center;
      & a{
        color: $color-exim-green;
        font-size: 2.5rem;
      }
    }
  }
  &__bottom{
    text-align: center;
    font-size: 1.2rem;
    text-decoration: none;
    color: $color-grey;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
}
@include respond(tab-port){
  .footer{
    // text-align: center;
    overflow: hidden;
    padding: 4rem 0rem;
    padding-bottom: 15rem;
    line-height: 1.5;
    left: 0;
    &__top{
      margin: auto;
      flex-wrap: wrap;
      justify-content: center;
      &_text-smaller{
        padding-right: 1rem;
      }
    }
    &__middle{
      flex-wrap: wrap;
    }
    &__bottom{
      flex-wrap: wrap;
    }
  }
};
