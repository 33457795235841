.blog{
  background: $color-white;
  margin: 2rem 25rem ;

  &__box{
     &_row{
        width: 100vw;
        position: relative;
        left: -43rem;
        // min-height: 65rem;
        // background: $color-purple;
        @include respond(tab-land){
          flex-direction: column;
          left: -12rem;
        }
        & .link{
          color: transparent;
        }
        & > img{
          height: 15rem;
        }
     }
    &_flex{
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    &_title{
      font-size: 3rem;
      font-weight: bold;
      color: $color-exim-dark-grey;
      margin-bottom: .5rem;
    }
    font-size: 1.6rem;
    line-height: 1.5;
    & h1,h2,h3,h4,h5,h6,img{
      margin: 1rem 0;
    }
    & a{
      display: block;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: bold;
      color: $color-exim-green;
    }
    & li{
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    & ul{
      list-style-type: circle;
      margin-left: 2rem;
      &:first-child{
      }
    }
    & img{
      width: 100%;
      height:40rem;
      object-fit: cover;
      object-position: center;
    }
    &_social{
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 15rem;
       & a{
         color: $color-exim-dark-grey;
         margin: 1.5rem;
         font-size: 5rem;
         text-shadow: 0 .5rem .5rem rgba($color-grey,.5);
         // & img{
         //   height: 100%;
         //   width: 100%;
         // }
       }

    }
  }
  @include respond(tab-land){
     margin: 2rem 2rem;

  }
}
#blog__img{
  height: 20rem;
}
