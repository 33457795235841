.landing__9{
  margin-bottom: 10rem;
   &_box{

     &-1{
       display:flex;
       justify-content: center;
       &--top{
         width: 30rem;
         margin: 5rem;
         & > h1{
           display:flex;
            align-items: center;
         }
         &  .img__1,.img__2,.img__3,.img__4,.img__5,.img__6{
           animation: color 20s linear infinite;
           padding: 1rem;
           height: 10rem;
           width: 10rem;
         }
         &  .img__2{
            animation-delay: 2s;
         }
         &  .img__3{
            animation-delay: 4s;
         }
         &  .img__4{
            animation-delay: 6s;
         }
         &  .img__5{
            animation-delay: 8s;
         }
         &  .img__6{
            animation-delay: 10s;
         }
         &  span{
           font-size: 1rem;
           color: $color-exim-dark-grey;
           margin-left: 6rem;
           font-weight: bold;
         }
         & > div{
           margin-top: 3rem;
           font-size: 1rem;
           color: $color-grey;
         }
       }
     }
   }
}
@include respond(tab-port){
  .landing__9{
    &_box-1{
      flex-direction: row;
      // flex-wrap: wrap;
      // flex-direction: column;
      overflow-x: scroll;
      &::-webkit-scrollbar {
            height: 5px;
          }
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            // width: 1px;
          }
            &::-webkit-scrollbar-thumb {
            background: $color-exim-blue;
              border-radius:10px;
          }
          &::-webkit-scrollbar-thumb:hover {

            background: #555;
          }
      &--top > div{
        font-size: 1.2rem;
        display: none;
      }
      padding-left: 25rem;
      &--top{
        &  .img__1,.img__2,.img__3,.img__4,.img__5,.img__6{
          height: 8rem;
          width: 8rem;
          padding: 2rem;
          box-sizing: content-box;
        }
      }
      &--top > h1{
        // width: 50vw;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        & span{
          margin: 0;
          margin-top: 1rem;
          font-size: 1.5rem;
          text-align: center;
        }
        & img{
        }
      //   border-radius: 50%;
      //   padding: 1.5rem;
      }
    }
  }
};
