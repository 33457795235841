.landing__5{
  margin-bottom: 16rem;
  &_count{
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 5rem;
    &-digit{
      margin: 1rem;
      min-height: 10rem;
      min-width: 6rem;
      font-size: 5rem;
      display: flex;
      justify-content:center;
      align-items: center;
      color: $color-white;
      font-weight: bold;
      background-image: linear-gradient(to bottom, #0064FF 50%,#0093FF 50%, #0093FF  100%);
      border-radius: 5px;
      box-shadow: 0 0 2rem rgba($color-black,.2);
    }
  }
  &_text{
    font-size: 1.5rem;
    color: $color-exim-dark-grey;
    text-align:center;
    margin-bottom: 2rem;
  }
}

.digit__wrapper{
  position: relative;
  & hr{
    position: absolute;
    top: 49.5%;
    left: 12%;
    width: 76%;
    border: none;
    border-top: 1px solid $color-exim-blue;

  }
}

@include respond(tab-land){
   .landing__5{
     &_count-digit{
        font-size: 4rem;
        margin: .5rem;
        min-height: 9rem;
        min-width: 5rem;
     }
   }
};
