.course{
  background: $color-white;
  overflow: hidden;
  &__other{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    margin-bottom: 10rem;
    &_title{
      display:none;
    }
    @include respond(tab-port){
      flex-direction: column;
      &_title{
        display:block;
        font-weight: bold;
        margin: -1rem 0 3rem 0;
        text-align: center;
        text-transform:capitalize;
        font-size: 2rem;
      }
    }
    &_flex{
      display: none;
      @include respond(tab-port){
        display: flex;
      }
    }
    &_img{
      flex-basis: 50%;
      height: 40rem;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;

      &Mob{
        display: none;
      }
      @include respond(tab-port){
        display: none;
        &Mob{
          display: block;
          height: 40rem;
        }
      }
    }
    &_text{
      flex-basis: 50%;
      padding: 5rem 0;
      position: relative;
      @include respond(tab-port){
        display: none;
      }
      &-title{
        color: $color-exim-blue;
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }
      &-content{
        font-size: 2rem;
        margin-bottom: 5rem;
        padding-right: 5rem;
      }
      & a{
        position: absolute;
        right: 0;
        bottom: 2rem;
        width: 20rem;

      }
    }
  }
  &__div{
    position: relative;
    &_row{
      position: absolute;
      width: calc(100% - 10rem);
      background-image: linear-gradient(to top,rgba($color-white,.8) 30% ,transparent 100%);
      bottom: 0;
      left: 5rem;
      padding-bottom:2rem;
      height: 20rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      z-index: 5;
      &-head{
        font-size: 3.5rem;
        font-weight: bold;
        text-transform: uppercase;
        flex-grow: 1;
      }
      &-a{
        padding: 0rem 5rem;
        margin-right: 3rem;
        color: $color-white;
        font-size: 1.5rem;
        background: $color-exim-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        animation: scale .5s linear 10;
        transition: all .5s;
        &:hover{
          background: rgba($color-exim-blue,.7);
        }
        & > *{
          margin: 1rem;

        }
      }
    }
    &_route{
      position: absolute;
      top: -11rem;
      left: 23rem;
      width: 68%;
      @include respond(tab-port){
        display: none;
        // width: 100rem;
        // top: 2rem;
        // left: 0rem;
        // transform: rotate(90deg)
      }
    }

  }
  &__btnWrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 4rem 15rem;
    &_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26rem;
      padding: 1rem 2rem;
      border: none;
      background: $color-exim-blue;
      font-size: 2rem;
      color: $color-white;
      border-radius: 18px;
      &:hover{
        transform: translateY(-3px);
        box-shadow: 0rem 3rem 3rem -1rem rgba($color-exim-blue,.7);
      }
      &:active{
        transform: translateY(0px);
        box-shadow: 0rem 1rem 3rem -1rem rgba($color-exim-blue,.7);
      }
      & span{
        margin-right: 6rem;
      }
    }
  }
  &__wrapper{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin: 15rem 5rem;
    &Mob{
      display: none;
    }
    @include respond(tab-port){
      display: none;
      &Mob{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &_grid{
          display: grid;
          grid-template-columns: repeat(2 , 1fr);
          grid-template-rows: repeat(2 , 1fr);
          grid-gap: 3rem 6rem;
          &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &--div{
              margin-left: 2rem;
              &  strong{
                font-size: 1.5rem;
                margin-bottom: 1rem;
              }
              & > div{
                font-size: 1.5rem;
              }
            }
          }
        }
        &_flex{
          display: flex;
          justify-content: center;
          align-items: center;
          &-a{
            margin: 1rem;
            font-size: 2rem;
            color: $color-exim-blue;
            border: 1px solid $color-exim-blue;
            padding: 2rem 3rem;
            min-width: 20rem;
            border-radius: 8px;
            text-align: center;
            &--mod{
              background: $color-exim-blue;
              color: $color-white;
            }
          }
        }
      }
    }

    // z-index: 0;
    &_box{
      box-shadow: 0 0 1rem #CBC9C9;
      width: 100%;
      &-lock{
        display: flex;
        justify-content:space-between;
        align-items: center;
        font-size: 1rem;
        width: 80%;
        & div{
          text-align: right;
          width: 100%;
        }
        & img{
        }
      }
      &-1{
        background: #FFFFFF;
      }
      &-2{
        background: #F4F4F4;
      }
      &-3{
        background: #EAEAEA;
      }
      &-4{
        background: #B7B7B7;
        // z-index: -1;
      }
      &-5{
        background: #CBC9C9;
      }
      &-6{
        background: #D8D8D8;
      }
      height: 18rem;
      // overflow: hidden;
      position: relative;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-title{
        color:#484848;
        text-align:center;
        font-size: 2rem;
        margin-bottom: 3rem;
        font-weight: bold;
        padding: 0 3rem;
      }
      &-count{
        position: absolute;
        top: -1rem;
        left:-1rem;
        font-size: 2.4rem;
        color: $color-white;
        background: $color-exim-dark-grey;
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
//        transform: translate(-50%,-50%);
      }
    }
  }
  @include respond(tab-port){
    &__btnWrap{
      justify-content: center;
      margin: 2rem;
    }
    &__wrapper{
      margin: 2rem;
      grid-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      &_box{
        max-width: 40rem;
        margin: auto;
      }
    }
  }

}
.bottomFade{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 20rem;
  background-image: linear-gradient(to top,rgba($color-white,1) ,transparent);
  color: transparent;
}
.course__other_grids{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 50%;
    // margin: 2rem;
    // margin-right: 5rem;
    margin-bottom: 3rem;
    & svg{
      margin-right: 1rem;
    }
    &--div{
      margin-left: 2rem;
      &  strong{
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      & > div{
        font-size: 1.5rem;
      }
    }
  }
}
