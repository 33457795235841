.landing__bar{
  display: flex;
  justify-content:space-around;
  align-items: center;
  padding: 3rem 2rem;
  box-shadow: 0 0 4rem rgba($color-black,.3);
  border-radius: 18px;
  margin: 8rem 0;
  background: $color-white;
  &_item{
    display: flex;
    justify-content:space-between;
    align-items: center;
    &-svg{
      color: $color-exim-dark-grey;
    }
    &-text{
       padding-left: 3rem;
       line-height: 1.3;
       font-size: 1.8rem;
       color: $color-black;
       max-width: 30rem;
    }
  }
}
@include respond(tab-port){
  .landing__bar{
    padding: 2rem 1rem;
    margin: 8rem 1rem;
    &_item{

      &-svg{
        height: 2.5rem;
      }
      &-text{
        width: 10rem;
        padding-left: 1rem;
        font-size: 1rem;
      }
    }
  }
};
