.more{
  font-size: 2.5rem;
  padding: 1.5rem 2rem;
  color: $color-white;
  display: block;
  margin: 2rem auto;
  background: $color-exim-blue;
  font-weight: bold;
  max-width: 20rem;
  border-radius: 18px;
  text-align: center;
  text-transform: capitalize;
  &:hover{
    box-shadow: 0 0 3rem rgba($color-exim-blue,.3);
    transform: translateY(-3px);
    text-decoration: none;
  }
  &:active{
    box-shadow: 0 1rem 3rem rgba($color-exim-blue,.3);
    transform: translateY(0px);
  }
}

.pagination{
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  & span{
     font-size: 3rem;
     padding: 1rem 3rem;
     letter-spacing: 2px;
     font-weight: bold;
  }
  &__btn{
    border: none;
    font-size: 3rem;
    font-weight: bold;
    color: $color-exim-dark-grey;
    padding: 1rem 2rem;
    // background: $color-exim-dark-grey;
    &--back{

    }
    &--forw{

    }
  }
  &__select{
    padding: 1.5rem 3rem;
    margin-left: 2rem;

  }
}

.customHr{
  margin: 5rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0rem;

  color: transparent;
  text-shadow: 1.5rem 1.5rem 1.5rem $color-grey;
  border-bottom: 1px solid $color-grey;
  width: 30%;
  opacity: 0.3;
}

.link{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: transparent;
}
//
