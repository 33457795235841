.mainBlogs{
  background: $color-white;
  &__1{
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 5rem 5rem;
     // margin-top: 0;
     position: relative;
     // margin-top: ;
     & > img{
       height: 40rem;
       width: 60rem;
       object-fit: cover;
       object-position: center;
     }
     &_text{
       padding-left: 5rem;
       line-height: 1.5;
       &-title{
         font-size: 2rem;
         font-weight: bolder;
         text-transform:uppercase;
         margin-bottom: 2rem;
         text-align: center;
         color: $color-exim-dark-grey;
       }
       &-content{
         font-size: 1.5rem;
         margin-bottom: 1rem;
       }
       &-detail{
         display: flex;
         justify-content: space-between;
         align-items: baseline;
         font-size: 2.2rem;
         color: $color-exim-grey;
         &--date{
            margin-top: 2.5rem;
            font-size: 1.4rem;
         }
         &--by{
           & strong{
             color: $color-black;
           }
         }
       }
     }
     transition: all .3s;
     padding: 2rem;
     &:hover{
       transform: scale(1.05);
       box-shadow: 0 0rem 2rem rgba($color-exim-grey,.5);
       padding: 2rem;
     }
  }

  @include respond(tab-land){
    &__1{
      // transform: scale(1.05);
      box-shadow: 0 0rem 2rem rgba($color-exim-grey,.5);
      // padding: 2rem;
      overflow: hidden;
       flex-direction: column;
       & > img{
         height: 30rem;
         width: 45rem;
         object-fit: cover;
         object-position: center;
         margin: 2rem;
       }
       &_text{
         max-width: 40rem;
         padding-left: 0rem;
         &-detail{
           font-size: 2rem;
         }
       }
    }
  }

  &__2{
    margin: 10rem 5rem;
    & h1{
      margin-bottom: 2rem;
    }
    &_flex{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      &-box{
        position: relative;
        box-shadow: 0 0 5rem rgba($color-exim-dark-grey,.5);
        flex-basis: 25%;
        padding: 4rem;
        border-radius: 18px;
        display: flex;
        min-height: 60vh;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        background: $color-white;
        margin: 1rem;
        @include respond(tab-port){
          min-height: 50vh;
        }
        &:hover{
          box-shadow: 0 2rem 3rem rgba($color-exim-dark-grey,.2);
          transform: translateY(-4px);
        }
        &:active{
          box-shadow: 0 1rem 3rem rgba($color-exim-dark-grey,.2);
          transform: translateY(-1px);
        }
        & img{
           margin-bottom: 1rem;
           height: 22rem;
           width: 30rem;
           object-fit: cover;
           object-position: center;
        }
        &--title{
          font-size: 2rem;
          font-weight: bold;
          color: $color-exim-dark-grey;
          margin-bottom: .5rem;
        }
        &--content{
          font-size: 1.4rem;
          color: $color-exim-grey;
          margin-bottom: 1rem;
        }
        &--date{
          font-size: 1rem;
          text-align: right;
          color: $color-grey;
        }
      }
    }
  }

  @include respond(tab-land){
    &__2{
      &_flex{

        justify-content: center;
        margin: 1rem;
        flex-direction: column;
        &-box{
          max-width: 40rem;
          margin-bottom: 2rem;
        }
      }
    }
  }

  &__3{
    display: flex;
    margin: 10rem 5rem;
    &:first-child{
      flex-basis: 40%;

    }
    &:nth-child(2){
      flex-basis: 60%;

    }
    &_1{
       & h1{
         text-decoration: underline;
       }
    }
    & h1{
      margin-left: 4rem;
      margin-bottom: 5rem;
    }
    &_arrow{
      font-size: 5rem;
      font-weight: bolder;
      text-align: center;
      color: $color-exim-dark-grey;
      animation: bubble .5s linear 3;
      max-width: 1rem;
      // margin: 1rem auto;
      display: block;
      position: relative;
      left: 50%;
      // transform: translateX(-50%);
      & img{
        cursor: pointer;
        height: 3rem;
        &:hover{
          animation: scale .3s linear infinite;
        }
      }
      &-up{
        transform: rotate(-90deg);
      }
      &-down{
         transform: rotate(90deg);
      }


    }
    &_slide{
      min-height: 50rem;
      margin: 0rem 4rem;
      // border: 1px solid rgba($color-black,.1);
      &-box{
        padding: 2rem;
        display: flex;
        position: relative;
        padding-top: 1rem;
        &:not(:last-child){
          border-bottom: 1px solid $color-exim-dark-grey;
        }
        &:hover{
          box-shadow: 0 2rem 3rem rgba($color-exim-dark-grey,.2);
          transform: translateY(-4px);
        }
        &:active{
          box-shadow: 0 1rem 3rem rgba($color-exim-dark-grey,.2);
          transform: translateY(-1px);
        }
        & > div{

          margin-right: 2rem;
        }
        & img{
          height: 15rem;
          width: 20rem;
          object-fit: cover;
          object-position: center;
        }
        &--title{
          font-weight: bold;
          font-size: 2.1rem;
          margin-bottom: .5rem;
          color: $color-exim-dark-grey;
        }
        &--content{
          font-size: 1.1rem;
          margin-bottom: .5rem;
          color: $color-exim-grey;
        }
        &--date{
          font-size: 1rem;
          margin-bottom: .5rem;
          color: $color-exim-dark-grey;
          text-align: right;
        }
      }
    }
  }

  @include respond(tab-port){
    &__3{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      &_1{
         flex-basis: 100%;
      }
      & h1{
        margin-left: 0rem;
        margin-bottom: 1rem;
        text-decoration: underline;
        text-align: center;
        margin-top: 3rem;
      }
      &_slide{
        margin: 2rem;
        max-width: 40rem;
        &-box{
          // flex-direction: column;

          &--title{
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
