.landing__11{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & .heading-primary{
    flex-basis: 100%;
  }
  & .slide{
    display: flex;
    margin: 5rem 0;
    flex-wrap: nowrap;
    position: relative;
    animation: slide 80s linear infinite;
    animation-fill-mode: both;
  }
  &_review{
    border-radius: 38px;
    padding: 1rem;
    margin: 1rem 2rem;
    width: 40rem;
    box-shadow: 0 1rem 2rem -.5rem rgba($color-black,.5);
    background: $color-white;
    z-index: 10;
    &-top{
      display: flex;
      justify-content:space-between;
      align-items: center;
      &--img{
         margin-right: 1rem;
         height: 8rem;
         border-radius: 50%;
         width: 8rem;
      }
      &--name{
        color: $color-exim-dark-grey;
        line-height: 1.5;
        font-size: 1.8rem;
      }
      &--rating{
        background: $color-exim-blue;
        padding: .5rem 1rem;
        border-radius: 5px;
        font-size: 1.7rem;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin-right: 1rem;
        color: $color-white;
        width: 5rem;
      }
    }
    &-bottom{
      margin-top: 1.5rem;
      font-size: 1.4rem;
      color: $color-grey;
      padding: 1.5rem;
      margin-bottom: 3rem;
    }
  }
  @include respond(tab-land){
    overflow: hidden;
  }
}
