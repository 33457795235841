.landing__1{
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 0 4rem;
  margin-top: 4rem;
  background-image: url(../../../assets/images/eximBack.svg);
  background-position: bottom;
  background-size: cover;
  &_text{
    width: 50%;
    transition: all 1s;
    &-biggest{
       // font-family: 'PT Serif', serif;
       font-size: 13.6rem;
       letter-spacing: 1.9rem;
       color: $color-exim-dark-grey;
       font-weight: bold;
       margin-bottom: -2.5rem;
       margin-left: -1rem;
       margin-top: 5rem;
    }
    &-bigger{
      // font-family: 'PT Serif', serif;
      font-size: 4.5rem;
      letter-spacing: 1.5rem;
      color: $color-exim-dark-grey;
      font-weight: 400;
      margin-bottom: 1rem;
    }
    &-smaller{
      font-size: 2.8rem;
      color: $color-exim-dark-grey;
      // font-style: italic;
      margin-bottom: 1rem;
    }
    &-smallest{
      font-size: 1.4rem;
      line-height: 1.5;
      color: $color-exim-dark-grey;
      font-weight: bold;
      // font-style: italic;
    }
    &-btns{
      display: flex;
      & a{
        font-size: 1.8rem;
        // font-weight: bold;
        // width: 25rem;
        border: none;
        padding:1rem 4rem;
        margin: 2rem 0rem;
        margin-bottom: 3rem;
        margin-top: 4rem;
        margin-right: 5rem;
        border-radius: 5px;
        box-shadow: 0rem 0rem 2rem rgba($color-exim-dark-grey,.5);
        transition: all .3rem;
        &:hover{
          transform: translateY(-3px);
          box-shadow: 0rem 1rem 3rem rgba($color-exim-dark-grey,.5);
        }
        &:active{
          transform: translateY(0px);
          box-shadow: 0rem 1rem 2rem rgba($color-exim-dark-grey,.5);
        }
      }
      &--join{
         animation: scale .5s linear 3;
         background: $color-exim-blue;
         color: $color-white;
         position: relative;
         display: flex;
         align-items: center;
         flex-wrap: nowrap;
          width: 30rem;
       //   word-wrap: break-word;
         & span{
           position: relative;
           left: 3.9rem;
           z-index: 5;
           width: 3.5rem;
           background: $color-exim-blue;
           color: transparent;
           clip-path: polygon(0 0, 79% 30%, 100% 50%, 82% 72%, 0 100%);
           clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
         }

         & img{
           font-weight: lighter;
           font-size: 3.5rem;
           margin-left:2rem;
           transition: all .3s;

           // line-height: 3;
         }
         &:hover > img{
            margin-left: 3.5rem;
         }
         // display: flex;
         // justify-content: center;
         // align-items: center;
      }
      &--trial{
        background: $color-white;
        color: $color-exim-blue;
      }

    }
  }
  &_image{
     display: flex;
     justify-content: center;
     align-items: center;
  }
}

.spin{
  animation: rotate2 1s linear 2;
}

@include respond(tab-land){
  .landing__1{
    flex-direction: column-reverse;
    &_text{
       width: 100%;
       text-align: center;
       &-btns{
         justify-content: center;
         & button{
            min-width: 15rem;
            margin-right: 1rem;
         }
       }
    }
    &_image{
      height: 40vh;
      // object-fit: cover;
      object-position:center;

    }
  }
};
//
