.blogs{
  background: $color-white;
  &__big{
    position: relative;
    height: 60vh;
    & img{
      position: relative;
      top: 0;
      left: -12rem;
      width: 100vw;
      height: 60vh;
      object-fit: cover;
      object-position: center;
      // z-index: -1;
      @include respond(tab-land){
         left: -2rem;
      }
    }
    & div{
      font-size: 15rem;
      letter-spacing: 1.5px;
      color: $color-white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: lighter;
      & strong{
        text-transform:uppercase;
        margin-right: 1rem;
      }
      @include respond(tab-land){
         font-size: 7rem;
      }
    }
  }
  & > h1{
    margin: 4rem 8rem;
    text-align: right;
    font-size: 2rem;
    font-weight: normal;
    & span{
      font-weight: bold;
    }

  }
  &__flex{
    display: flex;
    justify-content: space-between;
    margin: 2rem 8rem;
    flex-wrap: wrap;

    &_box{
      position: relative;
      flex-basis: 30%;
      padding: 2rem;
      // border-radius: 18px;
      background: $color-white;
      // box-shadow: 0 1rem 1rem rgba($color-exim-dark-grey,.2);
      margin-bottom: 3rem;
      &:hover{
        box-shadow: 0 2rem 3rem rgba($color-exim-dark-grey,.2);
        transform: translateY(-4px);
      }
      &:active{
        box-shadow: 0 1rem 3rem rgba($color-exim-dark-grey,.2);
        transform: translateY(-1px);
      }
      &-title{
        font-size: 2rem;
        font-weight: bold;
        color: $color-exim-dark-grey;
        margin-bottom: .5rem;
      }
      &-content{
        font-size: 1.4rem;
        color: $color-exim-grey;
        margin-bottom: 1rem;
      }
      &-date{
        margin-top: 1rem;
        color: $color-exim-dark-grey;
        text-align: right;
      }
      &-img{
        height: 20rem;
        width: 100%;
        object-fit: cover;
        object-position: center;
        margin-bottom: 1rem;
      }
      & a{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: block;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: bold;
        color: transparent;
      }
    }
    @include respond(tab-land){
      flex-direction:column;
      &-box{
        flex-basis: 100%;
      }
    }
  }
}
