.terms{
  margin: 0 20rem;
  margin-top: 10rem;
  &__heading{
    text-align: center;
    margin-bottom: 5rem;
  }
  &__subHeading{
     margin-bottom: 5rem;
     margin-bottom: 1rem;
     font-size: 2.5rem;
     // font-weight: bold;
  }
  &__para{
     font-size: 1.5rem;
     line-height: 1.5;
  }
  @include respond(tab-port){
    margin: 0 2rem;
    margin-top: 10rem;
  }
}
